import React from "react"
import * as styles from "./not-found.module.scss"
import { Helmet } from "react-helmet"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import NeonLogoSVG from "../../svg/neon-logo.svg";
import { Link } from "gatsby"

export default function NotFound() {

  return <>
    <Helmet>
      <meta charSet="utf-8" />
      <html lang="pl" />
      <title>Browar Koło</title>
      <meta name="description" content="Z pasji do piwa" />
      {/* <link rel="preload"
        as="font"
        href={FontUrl1}
        type="font/woff2"
        crossOrigin="anonymous" /> */}
        <meta name="theme-color" content="#242424"></meta>
    </Helmet>
    <div className={styles.content}>

    <div
          className={styles.listWrapper}
          // onClick={() => onCloseClick()}
        >
          <div className={styles.listItem}>
            <div className={styles.logoWrapper}>
              <NeonLogoSVG />
            </div>
          </div>
          <div className={styles.listItem}>
            <div>
              <div className={styles.textTop}>
                Nie znaleziono żądanej podstrony
              </div>
              <div className={styles.textBottom}>
                Requested page not found
              </div>
            </div>
          </div>
          <div className={styles.listItem}>
            <div className={styles.answerWrapper}>
              <Link 
                className={`${styles.answer} ${styles.answer}`}
                to="/"
              >
                Strona główna / Homepage
              </Link>
            </div>
          </div>
      </div>
    </div>
  </>
}
